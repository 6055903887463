import { checkPermission } from '@/utils'

export const tableOption = {
  search: checkPermission(['externaladmin:user:viewKnife:search']),
  height: '100%',
  menuWidth: 320,
  menuAlign: 'center',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSpan: 6,
  selection: true,
  searchLabelWidth: 99,
  column: [
    {
      label: '款式',
      prop: 'styleName',
      minWidth: 100
    },
    {
      label: '尺码',
      prop: 'sizeName',
      minWidth: 100
    },
    {
      label: '是否露白',
      prop: 'isDewWhite',
      dicData: [
        {
          label: '全部',
          value: 'all'
        },
        {
          label: '正常',
          value: '0'
        },
        {
          label: '露白',
          value: '1'
        }
      ]
    },
    {
      label: '分销商',
      prop: 'mainUserName',
      minWidth: 100,
      search: true,
      slot: true,
      hide: true
    },
    {
      label: '分销商',
      prop: 'createByName',
      minWidth: 100,
      slot: true
    },
    {
      label: '刀版图',
      prop: 'knife',
      width: 145,
      slot: true
    },
    {
      label: '效果图',
      prop: 'picture',
      slot: true,
      width: 145
    },
    {
      label: '原图',
      prop: 'originalMerge',
      slot: true,
      width: 145
    },
    {
      label: 'SKU',
      prop: 'sku',
      minWidth: 100
    },
    {
      label: '自定义SKU',
      prop: 'customSku',
      minWidth: 100
    },
    {
      prop: 'create_time',
      search: true,
      searchSpan: 9,
      searchLabel: '定制时间',
      searchFormSlot: true,
      hide: true
    },
    {
      prop: 'multiSearch',
      hide: true,
      search: true,
      searchSpan: 9,
      searchWidth: 394,
      searchLabelWidth: 0,
      searchFormSlot: true
    },
    {
      label: '定制时间',
      prop: 'createTime',
      minWidth: 100
    }
  ]
}
